/** @jsx jsx */
import { jsx } from "theme-ui"
// import React from "react"
import { Link } from "gatsby"

const NotFound = () => (
  <div sx={{ textAlign: `center` }}>
    <h1 sx={{ color: `#fec10d`, fontSize: [6, 6, 7] }}>Erro - Página não encontrada</h1>

    <div sx={{ fontSize: [2, 2, 3] }}>
      <p>Não sei o que você procurava, mas não está aqui!</p>
      <p>Tudo que você precisa está <Link to="/">aqui</Link></p>
    </div>
  </div>
)

export default NotFound